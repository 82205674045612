@mixin from($device) {
  @media screen and (min-width: $device) {
    @content
  }
};

@mixin until($device) {
  @media screen and (max-width: $device - 1px) {
    @content
  }
};
