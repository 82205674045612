@import './common.css';
@import './tns.css'; // slider
@import '_mixins.scss';

$font: "Cuprum", sans-serif;
$green: #84c225;

$colors: (
  "green": $green,
  "light-grey": #818181,
  "grey": #333,
  "white": #fff,
);

$gap: 32px !default;
// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 769px !default;
// 960px container + 4rem
$desktop: 960px + (2 * $gap) !default;
// 1152px container + 4rem
$widescreen: 1152px + (2 * $gap) !default;
// 1344px container + 4rem
$fullhd: 1344px + (2 * $gap) !default;

$text-aligment: left, right, center;

$sides: ("t": "top", "r": "right", "b": "bottom", "l": "left");
$spaces: ("m": "margin", "p": "padding");
$step: 0.5;
@for $i from 0 through 10 {
  $num: $i;
  $val: $i * $step;
  @each $space_literal, $space in $spaces {
    .#{$space_literal}x-#{$num} {
      #{$space}-left: #{$val}rem;
      #{$space}-right: #{$val}rem;
    }
    .#{$space_literal}y-#{$num} {
      #{$space}-top: #{$val}rem;
      #{$space}-bottom: #{$val}rem;
    }
    .#{$space_literal}-#{$num} {
      #{$space}: #{$val}rem;
    }
  }
  @each $side_literal, $side in $sides {
    @each $space_literal, $space in $spaces {
      .#{$space_literal}#{$side_literal}-#{$num} {
        #{$space}-#{$side}: #{$val}rem;
      } 	
    }
  }
}

// width - height
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
@for $i from 1 through 9 {
  .h-#{$i*10} {
    height: #{$i*10}+#{"%"};
  }
  .w-#{$i*10} {
    width: #{$i*10}+#{"%"};
  }
  @each $key, $res in (s: $tablet, m: $desktop, l: $widescreen, xl: $fullhd ) {
    @include until($res) {
      .h-#{$key}-#{$i*10} {
        height: #{$i*10}+#{"%"};
      }
      .w-#{$key}-#{$i*10} {
        width: #{$i*10}+#{"%"};
      }

    }
  }
  
}

// font
.font-075 {
  font-size: 0.75rem;
}
@for $i from 1 through 10 {
  $ratio: 0.1;
  .font-#{$i} {
    font-size: #{0.8 + ($ratio * $i)}rem;
  }
  .border-width-#{$i} {
    border-width: #{$i}px;
  }
}

@each $name, $color in $colors {
  .text-#{$name} {
    color: $color;
  }
  .bg-#{$name} {
    background: $color;
  }
  .text-#{$name}-hover:hover {
    color: $color;
  }
  .bg-#{$name}-hover:hover {
    background: $color;
  }
  .border-#{$name} {
    border-color: $color;
  }
}

@each $aligment in $text-aligment {
  .text-#{$aligment} {
    text-align: #{$aligment};
  }
}

@each $style in (solid, dashed, dotted) {
  .border-#{$style} {
    border-style: #{$style};
  }
}

.pointer {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.font-styled {
  font-family: $font;
}
@each $display in (block, flex, inline-block) {
  .#{$display} {
    display: $display
  }
}
.grow {
  flex-grow: 1;
}

.justify-content-flex-end {
  justify-content: flex-end;
}
.align-self-center {
  align-self: center;
}
.align-items-center {
  align-items: center;
}

.justify-self-center {
  justify-self: center;
}

.uppercase {
  text-transform: uppercase;
}
.bold {
  font-weight: bold;
}
.normal {
  font-weight: normal;
}
.nowrap {
  white-space: nowrap;
}

.categories {
  .title {
    font-family: $font;
  }
}
.categories-list {
  list-style: none;
  margin: 0;
  li {
    display: flex;
    font-family: $font;
    text-transform: uppercase;
    align-items: center;
    margin-bottom: .7rem;
    .ico {
      width: 23px;
      margin-right: 1rem;
      text-align: center;
      img {
        width: 20px;
      }
    }
    a {
      color: #333;
    }
    &.active a, span, a:hover {
      color: $green;
    }
  }
}

.build-list {
  .item {
    .options {
      list-style: none;
      margin: 0;
      column-count: 3;
      li {
        padding-bottom: 0.5rem;
      }
    }
  }
}

.triangle-bottom {
  display: flex;
  &:after {
    content: "";
    align-self: center;
    margin-left: 1rem;
    width: 0;
    height: 0;
    border-style: solid;
    margin-top: -4px;
    border-width: 8px 4px 0 4px;
    border-color: #333 transparent transparent transparent;
  }
  &:hover:after {
    border-top-color: map-get($colors, "green")
  }
  &.triangle-rotate-180 {
    &:after {
      transform: rotate(180deg);
    }
  }
}

.content-after {
  &:after {
    content: attr(data-after);
  }
}

ul.green-dotts {
  li {
    padding-left: 1.2rem;
    &:before {
      content: "";
      width: 4px;
      height: 4px;
      border-radius: 50%;
      margin-left: -.7rem;
      margin-top: .5rem;
      position: absolute;
      background: map-get($colors, 'green')
    }
  }
}

.pagination {
  list-style: none;
  display: flex;
  margin: 1rem 0;
  li {
    &:not(:last-child) {
      margin-right: .5rem;
    }
    a, span {
      display: block;
      padding: .5rem .8rem;
    }
    a.active, a.current, span {
      background: map-get($colors, 'green');
      color: #fff;
    }
    a:hover {
      background: lighten(map-get($colors, 'green'), 40%)
    }
  }
}


.sort-select {
  width: 110px;
  position: relative;
  select {
    font-family: $font;
    background: #fff;
    padding: .5rem 1rem .5rem .5rem;
    margin: 0;
  }
  &:after {
    content: "";
    position: absolute;
    right: .5rem;
    top: calc(50% - 4px);
    width: 6px;
    height: 6px;
    transform: rotate(45deg);
    border-style: solid;
    border-width: 0 2px 2px 0;
    border-color: transparent #333 #333 transparent;
  }
}

table.bordered {
  td {
  border: 1px solid map-get($colors, 'green')
  }
}
.icon {
  width: 40px;
}
.categories-carousel {
  display: flex;
  .item {
    width: 120px;
    .img {
      height: 60px;
      width: 45px;
      display: inline-flex;
      align-items: center;
      img {
        max-width: 50px;
        max-height: 50px;
        width: 100%;
      }
    }
  }
}
.categories-preview {
  .tns-outer {
    position: relative;
  }
  .tns-controls {
    [data-controls] {
      position: absolute;
      width: 15px;
      height: 15px;
      border: 2px solid white;
      border-color: transparent white white transparent;
      text-indent: -999px;
      overflow: hidden;
      top: calc(50% - 15px / 2);
      outline: none;
    }
    [data-controls="prev"] {
      transform: rotate(135deg);
      left: -20px;
    }
    [data-controls="next"] {
      transform: rotate(-45deg);
      right: -20px;
    }
  }
}

.item-gallery-wrapper {
  position: relative;
  .item-gallery {
    display: flex;
    align-items: center;
    .item {
      text-align: center;
    }
  }
  .tns-controls {
    position: absolute;
    bottom: 0;
    [data-controls] {
      background: map-get($colors, 'green');
      text-indent: -9999px;
      font-size: 0;
      padding: 0.8rem 1.2rem;
      &:not(:last-child) {
        margin-right: .5rem;
      }
      &:before {
        content: "";
        width: 16px;
        height: 16px;
        border: 2px solid white;
        border-color: transparent white white transparent;
        display: block;
        overflow: hidden;
      }
    }
    [data-controls="prev"]:before {
      transform: rotate(135deg);
      margin-right: -8px;
    }
    [data-controls="next"]:before {
      transform: rotate(-45deg);
      margin-left: -8px;
    }
  }
}

.cutted-content {
  height: auto;
  &+ .more {
    display: none;
  }
  &.collapsed {
    overflow: hidden;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 50px;
      background: linear-gradient(to bottom, transparent , #fff 80%, #fff);
    }
    &+ .more {
      display: inline-block;
      text-decoration: none;
      border-bottom: 1px dashed currentColor;
      &:hover {
        border-bottom-color: transparent;
      }
    }
  }
}